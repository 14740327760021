import React from 'react';
import { ArrowRight, Award, Tag, Sparkles } from 'lucide-react';

export function SponsoredAd() {
  return (
    <div className="relative overflow-hidden bg-gradient-to-br from-purple-50 via-white to-purple-50 rounded-lg shadow-lg border border-purple-100 p-6 mb-8 transition-transform hover:scale-[1.01] duration-300">
      {/* Sponsored Badge */}
      <div className="absolute top-4 right-4 flex items-center gap-2 bg-purple-50 text-purple-600 px-3 py-1.5 rounded-full border border-purple-100">
        <Award className="w-4 h-4" />
        <span className="text-sm font-medium">Sponsored</span>
      </div>

      <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-8">
        {/* Left Content */}
        <div className="flex-1">
          {/* Logo */}
          <img 
            src="https://www.vitalproteins.com/cdn/shop/files/vital_proteins_logo_horizontal_09b05023-247f-441c-8598-9296bc15bcae_400x.png?v=1613708082"
            alt="Vital Proteins UK"
            className="h-12 mb-6"
          />

          {/* Special Offer */}
          <div className="flex items-center gap-2 text-purple-600 mb-4">
            <Tag className="w-5 h-5" />
            <span className="font-semibold">Limited Time Offer</span>
          </div>

          {/* Title */}
          <h2 className="text-2xl md:text-3xl font-bold text-gray-900 mb-4 flex items-center gap-2">
            Enjoy 15% Off All Bundles!
            <Sparkles className="w-6 h-6 text-yellow-400" />
          </h2>

          {/* Description */}
          <p className="text-gray-600">
            Shop our wide range of Vital Proteins collagen bundles, where you'll find carefully selected products 
            that have been paired together to meet your specific collagen needs.
          </p>
        </div>

        {/* Right Content - CTA */}
        <div className="flex flex-col items-start lg:items-center">
          <a
            href="https://www.vitalproteins.co.uk/bundles.list?affil=awin&utm_content=https%3A%2F%2Fpromocodemania.co.uk&utm_term=Discount+Code&utm_source=AWin-1689663&utm_medium=affiliate&utm_campaign=AffiliateWin&sv1=affiliate&sv_campaign_id=1689663&awc=21716_1735540546_4791a8030965361badaba63f7219d2d6"
            target="_blank"
            rel="noopener noreferrer sponsored"
            className="group flex items-center gap-2 bg-purple-600 text-white px-8 py-3 rounded-lg font-semibold hover:bg-purple-700 transition-colors"
          >
            Get Deal
            <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
          </a>
        </div>
      </div>

      {/* Decorative Elements */}
      <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700" />
    </div>
  );
}