import React from 'react';
import { Link } from 'react-router-dom';
import { Dumbbell, Mail, MapPin } from 'lucide-react';
import { merchants, cities } from '../lib/data';

export function Footer() {
  return (
    <footer className="bg-gray-900 text-gray-100">
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
          {/* Company Info */}
          <div>
            <div className="flex items-center gap-2 mb-4">
              <Dumbbell className="w-6 h-6 text-blue-400" />
              <span className="font-bold text-white text-lg">Gym Locator</span>
            </div>
            <p className="text-sm mb-4 text-gray-300">
              Your trusted platform for finding the perfect gym across the UK. 
              From standard gyms to specialized facilities, we help you discover 
              the ideal fitness space.
            </p>
            <div className="space-y-2">
              <div className="flex items-center gap-2 text-gray-300 hover:text-blue-400 transition-colors">
                <Mail className="w-4 h-4" />
                <a href="mailto:info@screwhero.com">info@screwhero.com</a>
              </div>
              <div className="flex items-center gap-2 text-gray-300">
                <MapPin className="w-4 h-4" />
                <span>London, UK</span>
              </div>
            </div>
          </div>

          {/* Rest of the footer content remains the same */}
          {/* ... */}
        </div>

        {/* Bottom Bar */}
        <div className="flex flex-col md:flex-row justify-between items-center pt-8 border-t border-gray-800">
          <p className="text-sm text-gray-400 mb-4 md:mb-0">
            © {new Date().getFullYear()} Gym Locator. All rights reserved.
          </p>
          <div className="flex flex-wrap justify-center gap-4 text-sm">
            <Link to="/privacy-policy" className="text-gray-400 hover:text-blue-400 transition-colors">
              Privacy Policy
            </Link>
            <Link to="/terms-of-use" className="text-gray-400 hover:text-blue-400 transition-colors">
              Terms of Use
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}