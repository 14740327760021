import axios from 'axios';
import { API_KEYS } from '../constants';
import { isValidUKPostcode, formatPostcode } from './postcode';

interface Location {
  lat: number;
  lng: number;
}

export async function getCoordinates(postcode: string): Promise<Location | null> {
  try {
    if (!isValidUKPostcode(postcode)) {
      console.warn('Invalid UK postcode format:', postcode);
      return null;
    }

    // Format the postcode and encode it properly for the URL
    const formattedPostcode = formatPostcode(postcode);
    const encodedPostcode = encodeURIComponent(formattedPostcode);

    // First attempt: Try with postal_code component
    const response = await axios.get(
      'https://maps.googleapis.com/maps/api/geocode/json',
      {
        params: {
          address: `${encodedPostcode}, United Kingdom`,
          key: API_KEYS.GOOGLE,
          region: 'uk',
          components: 'country:GB'
        }
      }
    );

    if (response.data.status === 'OK' && response.data.results.length > 0) {
      const { lat, lng } = response.data.results[0].geometry.location;
      return { lat, lng };
    }

    // Second attempt: Try with a more general search
    if (response.data.status === 'ZERO_RESULTS') {
      const retryResponse = await axios.get(
        'https://maps.googleapis.com/maps/api/geocode/json',
        {
          params: {
            address: `${encodedPostcode}, UK`,
            key: API_KEYS.GOOGLE,
            region: 'uk'
          }
        }
      );

      if (retryResponse.data.status === 'OK' && retryResponse.data.results.length > 0) {
        const { lat, lng } = retryResponse.data.results[0].geometry.location;
        return { lat, lng };
      }
    }

    console.warn('No location found for postcode:', formattedPostcode);
    return null;
  } catch (error) {
    console.error('Error geocoding postcode:', error);
    return null;
  }
}

export function calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
  const R = 6371; // Earth's radius in kilometers
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  
  const a = 
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * 
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return Math.round((R * c) * 10) / 10; // Round to 1 decimal place
}

function toRad(degrees: number): number {
  return degrees * (Math.PI / 180);
}