import type { RouteMatch } from './types';
import { cities, merchants } from '../data';
import { isValidUKPostcode } from './validators';

export function parseRoute(pathname: string): RouteMatch {
  const [, merchantSlug, locationSlug, page] = pathname.split('/');

  const merchant = merchants.find(m => m.slug === merchantSlug);
  const isPostcode = locationSlug ? isValidUKPostcode(locationSlug) : false;
  
  let city = undefined;
  if (locationSlug) {
    city = isPostcode
      ? { name: locationSlug.toUpperCase(), slug: locationSlug.toLowerCase() }
      : cities.find(c => c.slug === locationSlug);
  }

  return {
    merchant,
    city,
    page: page ? parseInt(page, 10) : 1,
    isPostcode
  };
}