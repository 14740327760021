import axios from 'axios';
import type { SearchResult } from '../types';
import { API_KEYS, API_ENDPOINTS } from '../constants';
import { getCoordinates, calculateDistance } from '../utils/geocoding';
import { getCachedResults, cacheResults } from '../cache';
import { normalizePostcode } from '../utils/postcode';
import merchantData from '../data/merchants.json';

const RESULTS_PER_PAGE = 10;
const SEARCH_RADIUS = 50; // kilometers

export async function searchMerchants(
  merchantType: string,
  location: string,
  page = 1,
  isPostcode = false
): Promise<{ results: SearchResult[]; totalPages: number; totalResults: number }> {
  try {
    // Generate cache key using normalized location
    const normalizedLocation = isPostcode ? normalizePostcode(location) : location.toLowerCase();
    const cacheKey = `${merchantType.toLowerCase()}-${normalizedLocation}`;
    
    // Check cache first
    const cachedResults = await getCachedResults(cacheKey);
    if (cachedResults) {
      const totalResults = cachedResults.length;
      const totalPages = Math.ceil(totalResults / RESULTS_PER_PAGE);
      const paginatedResults = cachedResults.slice(
        (page - 1) * RESULTS_PER_PAGE,
        page * RESULTS_PER_PAGE
      );
      return { results: paginatedResults, totalPages, totalResults };
    }

    // Check static data
    const normalizedMerchantType = merchantType.toLowerCase().replace(/\s+/g, '-');
    const normalizedLocationKey = isPostcode ? normalizePostcode(location) : location.toLowerCase().replace(/\s+/g, '-');
    const staticData = merchantData[normalizedMerchantType]?.[normalizedLocationKey];

    let results: SearchResult[] = staticData?.results || [];

    // Fetch from Google Places API if needed
    if (!staticData || results.length < 10) {
      try {
        let searchLocation = location;
        let userCoords = null;

        if (isPostcode) {
          userCoords = await getCoordinates(location);
          if (!userCoords) {
            console.error('Failed to geocode postcode:', location);
            return { results: [], totalPages: 0, totalResults: 0 };
          }
          searchLocation = `${userCoords.lat},${userCoords.lng}`;
        }

        const response = await axios.post(
          API_ENDPOINTS.PLACES_SEARCH,
          {
            textQuery: `${merchantType} near ${searchLocation}`,
            languageCode: 'en',
            maxResultCount: 50,
            rankPreference: 'DISTANCE',
            locationBias: userCoords ? {
              circle: {
                center: {
                  latitude: userCoords.lat,
                  longitude: userCoords.lng
                },
                radius: SEARCH_RADIUS * 1000 // Convert to meters
              }
            } : undefined
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-Goog-Api-Key': API_KEYS.GOOGLE,
              'X-Goog-FieldMask': 'places.id,places.displayName,places.formattedAddress,places.rating,places.userRatingCount,places.websiteUri,places.internationalPhoneNumber,places.currentOpeningHours,places.location',
            },
          }
        );

        if (response.data.places) {
          const apiResults = response.data.places.map((place: any) => ({
            id: place.id,
            name: place.displayName.text,
            address: place.formattedAddress,
            rating: place.rating,
            totalRatings: place.userRatingCount,
            openNow: place.currentOpeningHours?.openNow,
            website: place.websiteUri,
            phoneNumber: place.internationalPhoneNumber,
            location: place.location
          }));

          // Merge API results with static data, removing duplicates
          const existingIds = new Set(results.map(r => r.id));
          apiResults.forEach(result => {
            if (!existingIds.has(result.id)) {
              results.push(result);
              existingIds.add(result.id);
            }
          });

          // Calculate distances if postcode search
          if (userCoords) {
            results = results.map(result => ({
              ...result,
              distance: calculateDistance(
                userCoords.lat,
                userCoords.lng,
                result.location.latitude,
                result.location.longitude
              )
            })).sort((a, b) => (a.distance || 0) - (b.distance || 0));
          }

          // Cache the combined results
          await cacheResults(cacheKey, results);
        }
      } catch (error) {
        console.error('Error fetching from Places API:', error);
      }
    }

    const totalResults = results.length;
    const totalPages = Math.ceil(totalResults / RESULTS_PER_PAGE);
    const paginatedResults = results.slice(
      (page - 1) * RESULTS_PER_PAGE,
      page * RESULTS_PER_PAGE
    );

    return {
      results: paginatedResults,
      totalPages,
      totalResults
    };
  } catch (error) {
    console.error('Error searching merchants:', error);
    return {
      results: [],
      totalPages: 0,
      totalResults: 0
    };
  }
}