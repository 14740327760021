import React from 'react';
import { MetaTags } from '../components/seo/MetaTags';
import { SearchForm } from '../components/SearchForm';
import { FeaturedCategories } from '../components/FeaturedCategories';
import { PopularMerchants } from '../components/PopularMerchants';
import { generateHomeMeta } from '../lib/seo/generators';
import { Dumbbell } from 'lucide-react';

export function HomePage() {
  const seo = generateHomeMeta();
  const heroImage = "https://images.unsplash.com/photo-1534438327276-14e5300c3a48?auto=format&fit=crop&w=1920";

  return (
    <div className="min-h-screen bg-gray-50">
      <MetaTags {...seo} />

      {/* Hero Section */}
      <div className="relative bg-gray-900 py-24">
        {/* Background Image with Overlay */}
        <div 
          className="absolute inset-0 bg-gradient-to-r from-gray-900 to-gray-800 mix-blend-multiply"
          style={{
            backgroundImage: `url(${heroImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        />

        <div className="relative container mx-auto px-4">
          <div className="text-center mb-12">
            <Dumbbell className="w-16 h-16 text-blue-400 mx-auto mb-6 animate-pulse" />
            <h1 className="text-4xl md:text-6xl font-bold text-white mb-6">
              Find Your Perfect Gym
            </h1>
            <p className="text-xl text-gray-300 max-w-2xl mx-auto">
              Discover and compare local gyms, fitness centers, and specialized training facilities
            </p>
          </div>
          <div className="max-w-3xl mx-auto">
            <SearchForm />
          </div>
        </div>
      </div>

      {/* Featured Categories */}
      <FeaturedCategories />

      {/* Popular Locations */}
      <div className="bg-gray-50 py-20">
        <div className="container mx-auto px-4">
          <div className="mb-16">
            <PopularMerchants
              title="Top-Rated Gyms in London"
              merchantType="Gym"
              merchantSlug="gym"
            />
          </div>
          
          <div className="pt-16 border-t border-gray-200">
            <PopularMerchants
              title="Popular Boxing Gyms in Manchester"
              merchantType="Boxing Gym"
              merchantSlug="boxing-gym"
            />
          </div>
        </div>
      </div>

      {/* Trust Indicators */}
      <div className="bg-white py-16">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
            <div>
              <div className="text-4xl font-bold text-blue-600 mb-2">500+</div>
              <div className="text-gray-600">Verified Gyms</div>
            </div>
            <div>
              <div className="text-4xl font-bold text-blue-600 mb-2">50K+</div>
              <div className="text-gray-600">Active Members</div>
            </div>
            <div>
              <div className="text-4xl font-bold text-blue-600 mb-2">30+</div>
              <div className="text-gray-600">Cities Covered</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}