import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';
import { ResultsList } from '../components/ResultsList';
import { Pagination } from '../components/Pagination';
import { SponsoredAd } from '../components/SponsoredAd';
import { MetaTags } from '../components/seo/MetaTags';
import { useRouteMatch } from '../lib/router/hooks';
import { useSearch } from '../lib/hooks';
import { generateSearchMeta } from '../lib/seo/generators';
import { Helmet } from 'react-helmet-async';

export function SearchResults() {
  const navigate = useNavigate();
  const { merchant, city, page = 1 } = useRouteMatch();
  
  if (!merchant || !city) {
    return null;
  }

  const { data, isLoading } = useSearch(merchant.name, city.name, page);
  const meta = generateSearchMeta(merchant, city, page);

  const handlePageChange = (newPage: number) => {
    if (newPage === 1) {
      navigate(`/${merchant.slug}/${city.slug}`);
    } else {
      navigate(`/${merchant.slug}/${city.slug}/${newPage}`);
    }
  };

  // Generate breadcrumb schema
  const breadcrumbSchema = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": "https://www.gymlocator.co.uk",
          "name": "Home"
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": `https://www.gymlocator.co.uk/${merchant.slug}`,
          "name": merchant.name
        }
      },
      {
        "@type": "ListItem",
        "position": 3,
        "item": {
          "@id": `https://www.gymlocator.co.uk/${merchant.slug}/${city.slug}`,
          "name": `${merchant.name} in ${city.name}`
        }
      }
    ]
  };

  // Generate collection page schema
  const collectionPageSchema = {
    "@context": "https://schema.org",
    "@type": "CollectionPage",
    "name": `${merchant.name} in ${city.name}`,
    "description": `Find the best ${merchant.name.toLowerCase()} in ${city.name}. Compare facilities, read reviews, and get membership information.`,
    "breadcrumb": breadcrumbSchema,
    "mainEntity": {
      "@type": "ItemList",
      "itemListElement": data?.results.map((result, index) => ({
        "@type": "LocalBusiness",
        "position": index + 1,
        "name": result.name,
        "address": result.address,
        "telephone": result.phoneNumber,
        "url": result.website,
        "aggregateRating": result.rating ? {
          "@type": "AggregateRating",
          "ratingValue": result.rating,
          "reviewCount": result.totalRatings
        } : undefined
      })) || []
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <MetaTags {...meta} />
      
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(collectionPageSchema)}
        </script>
      </Helmet>

      <div className="container mx-auto px-4">
        <SponsoredAd />

        <nav className="flex flex-wrap items-center text-sm text-gray-500 mb-4">
          <Link to="/" className="hover:text-blue-600">Home</Link>
          <ChevronRight className="w-4 h-4 mx-2" />
          <Link to={`/${merchant.slug}`} className="hover:text-blue-600">{merchant.name}</Link>
          <ChevronRight className="w-4 h-4 mx-2" />
          <span className="text-gray-900">{city.name}</span>
          {page > 1 && (
            <>
              <ChevronRight className="w-4 h-4 mx-2" />
              <span className="text-gray-900">Page {page}</span>
            </>
          )}
        </nav>

        <div className="mb-8">
          <h1 className="text-2xl md:text-3xl font-bold text-gray-900">
            {merchant.name} in {city.name}{page > 1 ? ` - Page ${page}` : ''}
          </h1>
          <p className="text-gray-600 mt-2">
            Find the best {merchant.name.toLowerCase()} in {city.name}
          </p>
          {data?.totalResults ? (
            <p className="text-sm text-gray-500 mt-1">
              Showing {((page - 1) * 10) + 1}-{Math.min(page * 10, data.totalResults)} of {data.totalResults} results
            </p>
          ) : null}
        </div>

        <div className="search-results-container">
          <ResultsList results={data?.results || []} loading={isLoading} />
        </div>

        {data?.totalPages && data.totalPages > 1 && (
          <Pagination
            currentPage={page}
            totalPages={data.totalPages}
            onPageChange={handlePageChange}
          />
        )}

        <div className="mt-8 bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-semibold text-gray-900 mb-4">
            About {merchant.name} in {city.name}
          </h2>
          <p className="text-gray-600">
            Gym Locator helps you find reliable {merchant.name.toLowerCase()} in {city.name}. 
            Compare local facilities, read member reviews, and get detailed information 
            about their amenities and membership options.
          </p>
        </div>
      </div>
    </div>
  );
}