export const META_CONTENT = {
  SITE_NAME: 'Gym Locator',
  BASE_URL: 'https://www.gymlocator.co.uk',
  DEFAULT_OG_IMAGE: 'https://www.gymlocator.co.uk/og-image.jpg',
  TWITTER_HANDLE: '@gymlocator',

  DESCRIPTIONS: {
    GYM: (city: string) => 
      `Find the best gyms in ${city}. Compare facilities, read reviews, and discover your perfect workout space.`,
    BOXING_GYM: (city: string) => 
      `Discover boxing gyms in ${city}. Compare training facilities, read reviews, and find expert boxing coaches.`,
    GYM_WITH_SAUNA: (city: string) => 
      `Find gyms with saunas in ${city}. Compare facilities, wellness amenities, and membership options.`,
    LADIES_ONLY_GYM: (city: string) => 
      `Discover ladies only gyms in ${city}. Find women-focused fitness facilities with dedicated training spaces.`,
    GYM_WITH_POOL: (city: string) => 
      `Find gyms with pools in ${city}. Compare swimming facilities, fitness amenities, and membership options.`
  },

  TITLES: {
    GYM: (city: string) => 
      `Gyms in ${city} | Find Your Perfect Workout Space`,
    BOXING_GYM: (city: string) => 
      `Boxing Gyms in ${city} | Expert Training Facilities`,
    GYM_WITH_SAUNA: (city: string) => 
      `Gyms with Saunas in ${city} | Fitness & Wellness`,
    LADIES_ONLY_GYM: (city: string) => 
      `Ladies Only Gyms in ${city} | Women's Fitness Centers`,
    GYM_WITH_POOL: (city: string) => 
      `Gyms with Pools in ${city} | Swimming & Fitness`,
    
    HOME: 'Gym Locator | Find Your Perfect Gym Near You',
    ABOUT: 'About Gym Locator | Your Fitness Directory',
    CONTACT: 'Contact Us | Gym Locator',
    CATEGORIES: 'Gym Categories | Find Local Fitness Centers',
    PRIVACY: 'Privacy Policy | Gym Locator',
    TERMS: 'Terms of Use | Gym Locator'
  }
};