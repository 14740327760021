import React from 'react';

export function AboutStory() {
  return (
    <div className="py-16 bg-gradient-to-b from-white to-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-3xl font-bold text-gray-900 mb-6 text-center">Our Story</h2>
          <div className="prose prose-lg mx-auto text-gray-600">
            <p>
              Gym Locator was founded with a clear mission: to make finding the perfect gym simple, 
              transparent, and reliable. We understand that choosing the right fitness facility is 
              crucial for achieving your health and fitness goals, which is why we've created a platform 
              that connects you with trusted local gyms.
            </p>
            <p>
              Our platform helps both fitness enthusiasts and beginners find the ideal gym that matches 
              their needs and preferences. We work with verified facilities that maintain their equipment 
              to the highest standards, ensuring you get access to quality fitness amenities for your 
              workout routine.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}