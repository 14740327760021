/**
 * Validates and formats UK postcodes
 * Handles all valid UK postcode formats including:
 * - AL3 7FD
 * - N1 9AB
 * - n19ab
 * - N19AB
 * - WC2H 9JQ
 * - wc2h9jq
 * - TW8 0QL
 * - SW1A 1AA
 * - sw1a1aa
 */

/**
 * Validates if a string is a valid UK postcode
 * Using a more comprehensive regex that covers all valid UK postcode formats
 */
export function isValidUKPostcode(postcode: string): boolean {
  // Remove all whitespace and convert to uppercase for consistent validation
  const cleanPostcode = postcode.replace(/\s+/g, '').toUpperCase();
  
  // Comprehensive UK postcode regex that covers all valid formats
  const postcodeRegex = /^([A-Z]{1,2}[0-9][A-Z0-9]?|[A-Z]{1,2}[0-9][0-9]|[A-Z]{1,2}[0-9]|[A-Z]{1,2}[0-9][A-Z])[0-9][A-Z]{2}$/i;
  
  return postcodeRegex.test(cleanPostcode);
}

/**
 * Formats a postcode to standard format with space
 * e.g., "AL37FD" becomes "AL3 7FD"
 */
export function formatPostcode(postcode: string): string {
  if (!isValidUKPostcode(postcode)) {
    return postcode;
  }

  // Remove all whitespace and convert to uppercase
  const cleanPostcode = postcode.replace(/\s+/g, '').toUpperCase();
  
  // Insert space before the last three characters
  return cleanPostcode.replace(/^(.+?)([0-9][A-Z]{2})$/, '$1 $2');
}

/**
 * Normalizes a postcode for consistent comparison
 * Removes spaces and converts to uppercase
 */
export function normalizePostcode(postcode: string): string {
  return postcode.replace(/\s+/g, '').toUpperCase();
}