import React from 'react';
import { Helmet } from 'react-helmet-async';
import { META_CONTENT } from '../../lib/constants/meta';
import type { MetaTagsProps } from '../../lib/seo/types';

export function MetaTags({ 
  title, 
  description, 
  keywords, 
  canonicalUrl,
  ogImage = META_CONTENT.DEFAULT_OG_IMAGE,
  noindex = false
}: MetaTagsProps) {
  const fullTitle = title.includes('|') ? title : `${title} | ${META_CONTENT.SITE_NAME}`;

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <html lang="en" />
      <title>{fullTitle}</title>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}
      
      {/* Robots Meta Tags */}
      <meta name="robots" content={`${noindex ? 'noindex' : 'index'}, follow`} />
      <meta name="googlebot" content={`${noindex ? 'noindex' : 'index'}, follow`} />
      
      {/* Canonical URL - CRITICAL for SEO */}
      <link rel="canonical" href={canonicalUrl} />
      
      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:site_name" content={META_CONTENT.SITE_NAME} />
      
      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ogImage} />
      <meta name="twitter:site" content={META_CONTENT.TWITTER_HANDLE} />
    </Helmet>
  );
}