import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search } from 'lucide-react';
import { cities, merchants } from '../lib/data';

export function SearchForm() {
  const navigate = useNavigate();
  const [merchantType, setMerchantType] = useState('');
  const [city, setCity] = useState('');
  const [postcode, setPostcode] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (merchantType && (city || postcode)) {
      const location = postcode || city;
      navigate(`/${merchantType}/${location.toLowerCase().replace(/\s+/g, '-')}`);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-4xl mx-auto space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <select
          value={merchantType}
          onChange={(e) => setMerchantType(e.target.value)}
          className="w-full p-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-amber-500 focus:border-amber-500"
          required
        >
          <option value="">Select Category</option>
          {merchants.map((merchant) => (
            <option key={merchant.slug} value={merchant.slug}>
              {merchant.name}
            </option>
          ))}
        </select>

        <select
          value={city}
          onChange={(e) => {
            setCity(e.target.value);
            setPostcode('');
          }}
          className="w-full p-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-amber-500 focus:border-amber-500"
          disabled={!!postcode}
        >
          <option value="">Select City</option>
          {cities.map((city) => (
            <option key={city.slug} value={city.slug}>
              {city.name}
            </option>
          ))}
        </select>

        <div className="relative">
          <input
            type="text"
            value={postcode}
            onChange={(e) => {
              setPostcode(e.target.value);
              setCity('');
            }}
            placeholder="Or enter postcode"
            className="w-full p-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-amber-500 focus:border-amber-500"
            disabled={!!city}
          />
        </div>
      </div>

      <button
        type="submit"
        className="w-full md:w-auto px-6 py-3 bg-amber-700 text-white rounded-lg hover:bg-amber-800 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2 flex items-center justify-center gap-2 transition-colors duration-200"
      >
        <Search size={20} />
        <span>Search</span>
      </button>
    </form>
  );
}