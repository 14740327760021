import React from 'react';
import { Shield, Users, Star, Clock, MapPin, Dumbbell } from 'lucide-react';

export function AboutFeatures() {
  return (
    <div className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="bg-gray-50 rounded-xl p-6">
            <Shield className="w-8 h-8 text-blue-600 mb-4" />
            <h3 className="text-xl font-semibold text-gray-900 mb-2">Verified Gyms</h3>
            <p className="text-gray-600">
              We carefully vet each fitness facility to ensure quality and safety standards.
            </p>
          </div>
          
          <div className="bg-gray-50 rounded-xl p-6">
            <Users className="w-8 h-8 text-blue-600 mb-4" />
            <h3 className="text-xl font-semibold text-gray-900 mb-2">Community Driven</h3>
            <p className="text-gray-600">
              Real reviews from gym members help you make informed decisions.
            </p>
          </div>
          
          <div className="bg-gray-50 rounded-xl p-6">
            <Star className="w-8 h-8 text-blue-600 mb-4" />
            <h3 className="text-xl font-semibold text-gray-900 mb-2">Quality Focus</h3>
            <p className="text-gray-600">
              We prioritize gyms that maintain high-quality equipment and facilities.
            </p>
          </div>
          
          <div className="bg-gray-50 rounded-xl p-6">
            <Clock className="w-8 h-8 text-blue-600 mb-4" />
            <h3 className="text-xl font-semibold text-gray-900 mb-2">Real-Time Updates</h3>
            <p className="text-gray-600">
              Stay informed with current membership prices and class schedules.
            </p>
          </div>
          
          <div className="bg-gray-50 rounded-xl p-6">
            <MapPin className="w-8 h-8 text-blue-600 mb-4" />
            <h3 className="text-xl font-semibold text-gray-900 mb-2">Local Focus</h3>
            <p className="text-gray-600">
              Find gyms in your area for convenient access to fitness facilities.
            </p>
          </div>
          
          <div className="bg-gray-50 rounded-xl p-6">
            <Dumbbell className="w-8 h-8 text-blue-600 mb-4" />
            <h3 className="text-xl font-semibold text-gray-900 mb-2">Wide Selection</h3>
            <p className="text-gray-600">
              From standard gyms to specialized facilities, find the perfect fit for your fitness goals.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}