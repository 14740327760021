import React from 'react';
import { useParams, Link, Navigate } from 'react-router-dom';
import { ChevronRight, MapPin } from 'lucide-react';
import { SEO } from '../components/SEO';
import { merchants, cities } from '../lib/data';

export function MerchantCategoryPage() {
  const { merchantType } = useParams<{ merchantType: string }>();
  const merchant = merchants.find(m => m.slug === merchantType);

  if (!merchant) {
    return <Navigate to="/404" replace />;
  }

  const title = `${merchant.name} Near Me | Find Local ${merchant.name}`;
  const description = `Find trusted ${merchant.name.toLowerCase()} across the UK. Compare local merchants, read reviews, and get contact information.`;

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <SEO 
        title={title}
        description={description}
        canonical={`https://www.hirefy.co.uk/${merchantType}`}
      />

      <div className="container mx-auto px-4">
        <nav className="flex items-center text-sm text-gray-500 mb-8">
          <Link to="/" className="hover:text-yellow-600">Home</Link>
          <ChevronRight className="w-4 h-4 mx-2" />
          <span className="text-gray-900">{merchant.name}</span>
        </nav>

        <div className="mb-12">
          <h1 className="text-3xl font-bold text-gray-900 mb-4">{merchant.name} Near Me</h1>
          <p className="text-lg text-gray-600 max-w-3xl">
            Find the best {merchant.name.toLowerCase()} in your area. Compare local suppliers, 
            read customer reviews, and get detailed information about their services and equipment.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {cities.map(city => (
            <Link
              key={city.slug}
              to={`/${merchantType}/${city.slug}`}
              className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow p-6"
            >
              <div className="flex items-start justify-between mb-3">
                <h2 className="text-lg font-semibold text-gray-900">
                  {city.name}
                </h2>
                <MapPin className="w-5 h-5 text-yellow-600 flex-shrink-0" />
              </div>
              <p className="text-sm text-gray-600">
                Find {merchant.name.toLowerCase()} in {city.name}. Get directions, opening hours, 
                and contact details.
              </p>
            </Link>
          ))}
        </div>

        <div className="mt-12 bg-white rounded-lg shadow-md p-8">
          <h2 className="text-2xl font-bold text-gray-900 mb-6">
            About {merchant.name}
          </h2>
          <div className="prose max-w-none text-gray-600">
            <p className="mb-4">
              HireFy helps you find reliable {merchant.name.toLowerCase()} across the UK. 
              Our comprehensive directory includes detailed information about local suppliers, 
              including customer reviews, contact details, and opening hours.
            </p>
            <p>
              Whether you're a professional contractor or a DIY enthusiast, we make it easy to 
              find the right {merchant.name.toLowerCase()} for your project. Browse our listings, 
              compare suppliers, and make informed decisions about where to hire your equipment.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}