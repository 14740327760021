import React from 'react';
import { Construction, Shield, Users, Star, Clock, MapPin } from 'lucide-react';
import { SEO } from '../components/SEO';
import { AboutHero } from '../components/about/AboutHero';
import { AboutFeatures } from '../components/about/AboutFeatures';
import { AboutStory } from '../components/about/AboutStory';

export function AboutPage() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <SEO 
        title="About HireFy | Your Equipment Hire Directory"
        description="HireFy helps you find quality equipment hire services. From tools to machinery, discover trusted local hire providers across the UK."
      />
      
      <AboutHero />
      <AboutFeatures />
      <AboutStory />
    </div>
  );
}