import { META_CONTENT } from '../constants/meta';
import type { City, Merchant } from '../types';
import type { GeneratedMeta } from './types';
import { CanonicalUrlGenerator } from '../utils/canonical';

export function generateHomeMeta(): GeneratedMeta {
  return {
    title: META_CONTENT.TITLES.HOME,
    description: 'Find your perfect gym near you. Compare local fitness facilities, read reviews, and start your fitness journey.',
    canonicalUrl: CanonicalUrlGenerator.getHomeCanonical(),
    keywords: 'gym finder, fitness centers, local gyms, boxing gyms, gym with pool, ladies gym'
  };
}

export function generateSearchMeta(merchant: Merchant, city: City, page?: number): GeneratedMeta {
  const pageTitle = page && page > 1 ? ` - Page ${page}` : '';
  const merchantType = merchant.slug.toUpperCase().replace(/-/g, '_');
  
  const title = META_CONTENT.TITLES[merchantType]?.(city.name) || 
    `${merchant.name} in ${city.name}${pageTitle} | Local Gyms`;
  
  const description = META_CONTENT.DESCRIPTIONS[merchantType]?.(city.name) ||
    `Find quality ${merchant.name.toLowerCase()} in ${city.name}. Compare facilities, read reviews, and get membership information.`;

  return {
    title,
    description,
    canonicalUrl: CanonicalUrlGenerator.getSearchCanonical(merchant, city, page),
    keywords: `${merchant.name.toLowerCase()}, gyms, ${city.name}, fitness centers, workout facilities`
  };
}