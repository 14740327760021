import { cities, merchants } from '../data';
import { isValidUKPostcode } from '../utils/postcode';

export { isValidUKPostcode };

export function isValidMerchant(slug: string): boolean {
  return merchants.some(m => m.slug === slug);
}

export function isValidCity(slug: string): boolean {
  return cities.some(c => c.slug === slug);
}

export function isValidPage(page: string): boolean {
  const pageNum = parseInt(page, 10);
  return !isNaN(pageNum) && pageNum > 0;
}

export function isStaticPage(slug: string): boolean {
  return ['about', 'contact', 'categories', 'privacy-policy', 'terms-of-use'].includes(slug);
}