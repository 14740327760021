import React from 'react';
import { Mail, MapPin, Construction, Clock } from 'lucide-react';
import { SEO } from '../components/SEO';

export function ContactPage() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white py-12">
      <SEO 
        title="Contact HireFy"
        description="Get in touch with HireFy. We're here to help you find the right equipment hire solutions for your projects."
      />
      
      <div className="container mx-auto px-4 max-w-4xl">
        <div className="text-center mb-12">
          <Construction className="w-12 h-12 text-yellow-600 mx-auto mb-4" />
          <h1 className="text-3xl font-bold text-gray-900 mb-4">Contact Us</h1>
          <p className="text-lg text-gray-600">
            Have questions about equipment hire? We're here to help you find the right solutions for your projects.
          </p>
        </div>
        
        <div className="bg-white rounded-lg shadow-lg p-8">
          <div className="grid gap-8">
            <div className="flex items-start gap-3">
              <Mail className="w-6 h-6 text-yellow-600 mt-1" />
              <div>
                <h2 className="font-medium text-gray-900 mb-1">Email Us</h2>
                <a 
                  href="mailto:info@screwhero.com" 
                  className="text-yellow-600 hover:text-yellow-700 transition-colors"
                >
                  info@screwhero.com
                </a>
                <p className="text-sm text-gray-600 mt-2">
                  We aim to respond to all inquiries within 24 hours during business days.
                </p>
              </div>
            </div>

            <div className="flex items-start gap-3">
              <MapPin className="w-6 h-6 text-yellow-600 mt-1" />
              <div>
                <h2 className="font-medium text-gray-900 mb-1">Visit Our Office</h2>
                <p className="text-gray-600">
                  34b York Way<br />
                  London, King's Cross<br />
                  N1 9AB<br />
                  United Kingdom
                </p>
              </div>
            </div>

            <div className="flex items-start gap-3">
              <Clock className="w-6 h-6 text-yellow-600 mt-1" />
              <div>
                <h2 className="font-medium text-gray-900 mb-4">Business Hours</h2>
                <dl className="space-y-2">
                  <div className="flex justify-between">
                    <dt className="text-gray-600">Monday - Friday</dt>
                    <dd className="text-gray-900">9:00 AM - 6:00 PM</dd>
                  </div>
                  <div className="flex justify-between">
                    <dt className="text-gray-600">Saturday</dt>
                    <dd className="text-gray-900">10:00 AM - 4:00 PM</dd>
                  </div>
                  <div className="flex justify-between">
                    <dt className="text-gray-600">Sunday</dt>
                    <dd className="text-gray-900">Closed</dd>
                  </div>
                </dl>
                <p className="text-sm text-gray-600 mt-4">
                  All times are in UK time (GMT/BST)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}