import React from 'react';
import { Dumbbell } from 'lucide-react';

export function AboutHero() {
  return (
    <div className="relative overflow-hidden bg-gray-900 text-white py-20">
      <div className="absolute inset-0 opacity-20" style={{
        backgroundImage: "url('https://images.unsplash.com/photo-1534438327276-14e5300c3a48?auto=format&fit=crop&w=1920')",
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }} />
      <div className="container mx-auto px-4 relative">
        <div className="max-w-3xl">
          <div className="flex items-center gap-3 mb-6">
            <Dumbbell className="w-10 h-10 text-blue-400" />
            <h1 className="text-4xl md:text-5xl font-bold">About Gym Locator</h1>
          </div>
          <p className="text-xl text-gray-100">
            Your trusted partner in finding the perfect fitness facility across the UK
          </p>
        </div>
      </div>
    </div>
  );
}