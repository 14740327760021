import type { City, Merchant } from './types';

export const cities: City[] = [
  { name: 'Abingdon-on-Thames', slug: 'abingdon-on-thames' },
  { name: 'Accrington', slug: 'accrington' },
  { name: 'Aldershot', slug: 'aldershot' },
  { name: 'Altrincham', slug: 'altrincham' },
  { name: 'Andover', slug: 'andover' },
  { name: 'Arnold', slug: 'arnold' },
  { name: 'Ashford', slug: 'ashford' },
  { name: 'Ashton-under-Lyne', slug: 'ashton-under-lyne' },
  { name: 'Aylesbury', slug: 'aylesbury' },
  { name: 'Bamber Bridge', slug: 'bamber-bridge' },
  { name: 'Banbury', slug: 'banbury' },
  { name: 'Barnsley', slug: 'barnsley' },
  { name: 'Barnstaple', slug: 'barnstaple' },
  { name: 'Barrow-in-Furness', slug: 'barrow-in-furness' },
  { name: 'Basildon', slug: 'basildon' },
  { name: 'Basingstoke', slug: 'basingstoke' },
  { name: 'Bath', slug: 'bath' },
  { name: 'Batley', slug: 'batley' },
  { name: 'Bedford', slug: 'bedford' },
  { name: 'Bedworth', slug: 'bedworth' },
  { name: 'Beeston', slug: 'beeston' },
  { name: 'Bebington', slug: 'bebington' },
  { name: 'Beverley', slug: 'beverley' },
  { name: 'Bexhill-on-Sea', slug: 'bexhill-on-sea' },
  { name: 'Bicester', slug: 'bicester' },
  { name: 'Billericay', slug: 'billericay' },
  { name: 'Billingham', slug: 'billingham' },
  { name: 'Bilston', slug: 'bilston' },
  { name: 'Birkenhead', slug: 'birkenhead' },
  { name: 'Birmingham', slug: 'birmingham' },
  { name: 'Bishop\'s Stortford', slug: 'bishops-stortford' },
  { name: 'Blackburn', slug: 'blackburn' },
  { name: 'Blackpool', slug: 'blackpool' },
  { name: 'Bletchley', slug: 'bletchley' },
  { name: 'Bloxwich', slug: 'bloxwich' },
  { name: 'Blyth', slug: 'blyth' },
  { name: 'Bognor Regis', slug: 'bognor-regis' },
  { name: 'Bolton', slug: 'bolton' },
  { name: 'Bootle', slug: 'bootle' },
  { name: 'Borehamwood', slug: 'borehamwood' },
  { name: 'Boston', slug: 'boston' },
  { name: 'Bracknell', slug: 'bracknell' },
  { name: 'Braintree', slug: 'braintree' },
  { name: 'Brentwood', slug: 'brentwood' },
  { name: 'Bridgwater', slug: 'bridgwater' },
  { name: 'Bridlington', slug: 'bridlington' },
  { name: 'Brierley Hill', slug: 'brierley-hill' },
  { name: 'Brighouse', slug: 'brighouse' },
  { name: 'Brighton and Hove', slug: 'brighton-and-hove' },
  { name: 'Bristol', slug: 'bristol' },
  { name: 'Bromsgrove', slug: 'bromsgrove' },
  { name: 'Burgess Hill', slug: 'burgess-hill' },
  { name: 'Burnley', slug: 'burnley' },
  { name: 'Burton upon Trent', slug: 'burton-upon-trent' },
  { name: 'Bury', slug: 'bury' },
  { name: 'Bury St Edmunds', slug: 'bury-st-edmunds' },
  { name: 'Camberley', slug: 'camberley' },
  { name: 'Cambridge', slug: 'cambridge' },
  { name: 'Cannock', slug: 'cannock' },
  { name: 'Canterbury', slug: 'canterbury' },
  { name: 'Canvey Island', slug: 'canvey-island' },
  { name: 'Carlisle', slug: 'carlisle' },
  { name: 'Carlton', slug: 'carlton' },
  { name: 'Castleford', slug: 'castleford' },
  { name: 'Caversham', slug: 'caversham' },
  { name: 'Chadderton', slug: 'chadderton' },
  { name: 'Chandler\'s Ford', slug: 'chandlers-ford' },
  { name: 'Chatham', slug: 'chatham' },
  { name: 'Chelmsford', slug: 'chelmsford' },
  { name: 'Cheltenham', slug: 'cheltenham' },
  { name: 'Cheshunt', slug: 'cheshunt' },
  { name: 'Chester', slug: 'chester' },
  { name: 'Chesterfield', slug: 'chesterfield' },
  { name: 'Chichester', slug: 'chichester' },
  { name: 'Chippenham', slug: 'chippenham' },
  { name: 'Chorley', slug: 'chorley' },
  { name: 'Christchurch', slug: 'christchurch' },
  { name: 'Clacton-on-Sea', slug: 'clacton-on-sea' },
  { name: 'Colchester', slug: 'colchester' },
  { name: 'Congleton', slug: 'congleton' },
  { name: 'Corby', slug: 'corby' },
  { name: 'Coventry', slug: 'coventry' },
  { name: 'Crawley', slug: 'crawley' },
  { name: 'Crewe', slug: 'crewe' },
  { name: 'Crosby', slug: 'crosby' },
  { name: 'Darlington', slug: 'darlington' },
  { name: 'Dartford', slug: 'dartford' },
  { name: 'Denton', slug: 'denton' },
  { name: 'Derby', slug: 'derby' },
  { name: 'Dewsbury', slug: 'dewsbury' },
  { name: 'Didcot', slug: 'didcot' },
  { name: 'Doncaster', slug: 'doncaster' },
  { name: 'Dover', slug: 'dover' },
  { name: 'Dudley', slug: 'dudley' },
  { name: 'Dunstable', slug: 'dunstable' },
  { name: 'Durham', slug: 'durham' },
  { name: 'Eastbourne', slug: 'eastbourne' },
  { name: 'Eastleigh', slug: 'eastleigh' },
  { name: 'Eccles', slug: 'eccles' },
  { name: 'Ellesmere Port', slug: 'ellesmere-port' },
  { name: 'Epsom', slug: 'epsom' },
  { name: 'Exeter', slug: 'exeter' },
  { name: 'Exmouth', slug: 'exmouth' },
  { name: 'Fareham', slug: 'fareham' },
  { name: 'Farnborough', slug: 'farnborough' },
  { name: 'Fleet', slug: 'fleet' },
  { name: 'Folkestone', slug: 'folkestone' },
  { name: 'Fulwood', slug: 'fulwood' },
  { name: 'Gateshead', slug: 'gateshead' },
  { name: 'Gillingham', slug: 'gillingham' },
  { name: 'Gloucester', slug: 'gloucester' },
  { name: 'Gosport', slug: 'gosport' },
  { name: 'Grantham', slug: 'grantham' },
  { name: 'Gravesend', slug: 'gravesend' },
  { name: 'Grays', slug: 'grays' },
  { name: 'Great Malvern', slug: 'great-malvern' },
  { name: 'Grimsby', slug: 'grimsby' },
  { name: 'Guildford', slug: 'guildford' },
  { name: 'Halesowen', slug: 'halesowen' },
  { name: 'Halifax', slug: 'halifax' },
  { name: 'Harlow', slug: 'harlow' },
  { name: 'Harpenden', slug: 'harpenden' },
  { name: 'Harrogate', slug: 'harrogate' },
  { name: 'Hartlepool', slug: 'hartlepool' },
  { name: 'Hastings', slug: 'hastings' },
  { name: 'Hatfield', slug: 'hatfield' },
  { name: 'Havant', slug: 'havant' },
  { name: 'Haywards Heath', slug: 'haywards-heath' },
  { name: 'Hemel Hempstead', slug: 'hemel-hempstead' },
  { name: 'Hereford', slug: 'hereford' },
  { name: 'High Wycombe', slug: 'high-wycombe' },
  { name: 'Hinckley', slug: 'hinckley' },
  { name: 'Hitchin', slug: 'hitchin' },
  { name: 'Hoddesdon', slug: 'hoddesdon' },
  { name: 'Horndean', slug: 'horndean' },
  { name: 'Horsham', slug: 'horsham' },
  { name: 'Hucknall', slug: 'hucknall' },
  { name: 'Huddersfield', slug: 'huddersfield' },
  { name: 'Hull', slug: 'hull' },
  { name: 'Huyton with Roby', slug: 'huyton-with-roby' },
  { name: 'Hyde', slug: 'hyde' },
  { name: 'Ilkeston', slug: 'ilkeston' },
  { name: 'Ipswich', slug: 'ipswich' },
  { name: 'Keighley', slug: 'keighley' },
  { name: 'Kettering', slug: 'kettering' },
  { name: 'Kidderminster', slug: 'kidderminster' },
  { name: 'King\'s Lynn', slug: 'kings-lynn' },
  { name: 'Kingswinford', slug: 'kingswinford' },
  { name: 'Kingswood and Fishponds', slug: 'kingswood-and-fishponds' },
  { name: 'Kirkby', slug: 'kirkby' },
  { name: 'Lancaster', slug: 'lancaster' },
  { name: 'Leeds', slug: 'leeds' },
  { name: 'Leigh', slug: 'leigh' },
  { name: 'Leighton Buzzard', slug: 'leighton-buzzard' },
  { name: 'Leicester', slug: 'leicester' },
  { name: 'Letchworth Garden City', slug: 'letchworth-garden-city' },
  { name: 'Leyland', slug: 'leyland' },
  { name: 'Lichfield', slug: 'lichfield' },
  { name: 'Lincoln', slug: 'lincoln' },
  { name: 'Liverpool', slug: 'liverpool' },
  { name: 'Locks Heath', slug: 'locks-heath' },
  { name: 'London', slug: 'london' },
  { name: 'Long Eaton', slug: 'long-eaton' },
  { name: 'Loughborough', slug: 'loughborough' },
  { name: 'Loughton', slug: 'loughton' },
  { name: 'Lowestoft', slug: 'lowestoft' },
  { name: 'Lytham St Anne\'s', slug: 'lytham-st-annes' },
  { name: 'Macclesfield', slug: 'macclesfield' },
  { name: 'Maidenhead', slug: 'maidenhead' },
  { name: 'Maidstone', slug: 'maidstone' },
  { name: 'Manchester', slug: 'manchester' },
  { name: 'Mansfield', slug: 'mansfield' },
  { name: 'Margate', slug: 'margate' },
  { name: 'Middlesbrough', slug: 'middlesbrough' },
  { name: 'Middleton', slug: 'middleton' },
  { name: 'Milton Keynes', slug: 'milton-keynes' },
  { name: 'Morecambe', slug: 'morecambe' },
  { name: 'Morley', slug: 'morley' },
  { name: 'Nelson', slug: 'nelson' },
  { name: 'New Haw, West Byfleet and Sheerwater', slug: 'new-haw-west-byfleet-and-sheerwater' },
  { name: 'Newbury', slug: 'newbury' },
  { name: 'Newcastle', slug: 'newcastle' },
  { name: 'Newcastle-under-Lyme', slug: 'newcastle-under-lyme' },
  { name: 'Norwich', slug: 'norwich' },
  { name: 'Nottingham', slug: 'nottingham' },
  { name: 'Nuneaton', slug: 'nuneaton' },
  { name: 'Oldbury', slug: 'oldbury' },
  { name: 'Oldham', slug: 'oldham' },
  { name: 'Oxford', slug: 'oxford' },
  { name: 'Paignton', slug: 'paignton' },
  { name: 'Peterborough', slug: 'peterborough' },
  { name: 'Plymouth', slug: 'plymouth' },
  { name: 'Pontefract', slug: 'pontefract' },
  { name: 'Poole', slug: 'poole' },
  { name: 'Portsmouth', slug: 'portsmouth' },
  { name: 'Prescot', slug: 'prescot' },
  { name: 'Preston', slug: 'preston' },
  { name: 'Prestwich', slug: 'prestwich' },
  { name: 'Pudsey', slug: 'pudsey' },
  { name: 'Radcliffe', slug: 'radcliffe' },
  { name: 'Ramsgate', slug: 'ramsgate' },
  { name: 'Rayleigh', slug: 'rayleigh' },
  { name: 'Reading', slug: 'reading' },
  { name: 'Redcar', slug: 'redcar' },
  { name: 'Redditch', slug: 'redditch' },
  { name: 'Redhill', slug: 'redhill' },
  { name: 'Rochester', slug: 'rochester' },
  { name: 'Rochdale', slug: 'rochdale' },
  { name: 'Rotherham', slug: 'rotherham' },
  { name: 'Royal Leamington Spa', slug: 'royal-leamington-spa' },
  { name: 'Royal Sutton Coldfield', slug: 'royal-sutton-coldfield' },
  { name: 'Royal Tunbridge Wells', slug: 'royal-tunbridge-wells' },
  { name: 'Rugby', slug: 'rugby' },
  { name: 'Runcorn', slug: 'runcorn' },
  { name: 'Rushden', slug: 'rushden' },
  { name: 'Rustington', slug: 'rustington' },
  { name: 'Sale', slug: 'sale' },
  { name: 'Salisbury', slug: 'salisbury' },
  { name: 'Salford', slug: 'salford' },
  { name: 'Scarborough', slug: 'scarborough' },
  { name: 'Scunthorpe', slug: 'scunthorpe' },
  { name: 'Sedgley', slug: 'sedgley' },
  { name: 'Sheffield', slug: 'sheffield' },
  { name: 'Shrewsbury', slug: 'shrewsbury' },
  { name: 'Sittingbourne', slug: 'sittingbourne' },
  { name: 'Skelmersdale', slug: 'skelmersdale' },
  { name: 'Slough', slug: 'slough' },
  { name: 'Smethwick', slug: 'smethwick' },
  { name: 'Solihull', slug: 'solihull' },
  { name: 'Southampton', slug: 'southampton' },
  { name: 'Southend-on-Sea', slug: 'southend-on-sea' },
  { name: 'South Shields', slug: 'south-shields' },
  { name: 'Southport', slug: 'southport' },
  { name: 'Spalding', slug: 'spalding' },
  { name: 'St Albans', slug: 'st-albans' },
  { name: 'St Helens', slug: 'st-helens' },
  { name: 'St Neots', slug: 'st-neots' },
  { name: 'Stafford', slug: 'stafford' },
  { name: 'Stevenage', slug: 'stevenage' },
  { name: 'Stockport', slug: 'stockport' },
  { name: 'Stockton-on-Tees', slug: 'stockton-on-tees' },
  { name: 'Stourbridge', slug: 'stourbridge' },
  { name: 'Sunderland', slug: 'sunderland' },
  { name: 'Sutton in Ashfield', slug: 'sutton-in-ashfield' },
  { name: 'Swadlincote', slug: 'swadlincote' },
  { name: 'Swindon', slug: 'swindon' },
  { name: 'Tamworth', slug: 'tamworth' },
  { name: 'Taunton', slug: 'taunton' },
  { name: 'Telford', slug: 'telford' },
  { name: 'Thundersley and South Benfleet', slug: 'thundersley-and-south-benfleet' },
  { name: 'Tipton', slug: 'tipton' },
  { name: 'Tonbridge', slug: 'tonbridge' },
  { name: 'Torquay', slug: 'torquay' },
  { name: 'Trowbridge', slug: 'trowbridge' },
  { name: 'Tynemouth', slug: 'tynemouth' },
  { name: 'Urmston', slug: 'urmston' },
  { name: 'Wakefield', slug: 'wakefield' },
  { name: 'Wallasey', slug: 'wallasey' },
  { name: 'Wallsend', slug: 'wallsend' },
  { name: 'Walsall', slug: 'walsall' },
  { name: 'Warrington', slug: 'warrington' },
  { name: 'Warwick', slug: 'warwick' },
  { name: 'Washington', slug: 'washington' },
  { name: 'Waterlooville', slug: 'waterlooville' },
  { name: 'Watford', slug: 'watford' },
  { name: 'Wellingborough', slug: 'wellingborough' },
  { name: 'Welwyn Garden City', slug: 'welwyn-garden-city' },
  { name: 'West Bridgford', slug: 'west-bridgford' },
  { name: 'West Bromwich', slug: 'west-bromwich' },
  { name: 'West Molesey', slug: 'west-molesey' },
  { name: 'Weston-super-Mare', slug: 'weston-super-mare' },
  { name: 'Weymouth', slug: 'weymouth' },
  { name: 'Whitley Bay', slug: 'whitley-bay' },
  { name: 'Whitstable', slug: 'whitstable' },
  { name: 'Widnes', slug: 'widnes' },
  { name: 'Wigan', slug: 'wigan' },
  { name: 'Wigston', slug: 'wigston' },
  { name: 'Willenhall', slug: 'willenhall' },
  { name: 'Winchester', slug: 'winchester' },
  { name: 'Windsor', slug: 'windsor' },
  { name: 'Winsford', slug: 'winsford' },
  { name: 'Witney', slug: 'witney' },
  { name: 'Woking', slug: 'woking' },
  { name: 'Wokingham', slug: 'wokingham' },
  { name: 'Worcester', slug: 'worcester' },
  { name: 'Worksop', slug: 'worksop' },
  { name: 'Worthing', slug: 'worthing' },
  { name: 'Wythenshawe', slug: 'wythenshawe' },
  { name: 'Yeovil', slug: 'yeovil' },
  { name: 'York', slug: 'york' }
].sort((a, b) => a.name.localeCompare(b.name));

export const merchants: Merchant[] = [
  { name: 'Gym', slug: 'gym' },
  { name: 'Boxing Gym', slug: 'boxing-gym' },
  { name: 'Gym with Sauna', slug: 'gym-with-sauna' },
  { name: 'Ladies Only Gym', slug: 'ladies-only-gym' },
  { name: 'Gym with Pool', slug: 'gym-with-pool' }
].sort((a, b) => a.name.localeCompare(b.name));