import React from 'react';
import { Link } from 'react-router-dom';
import type { CategoryConfig } from '../../lib/types';

interface CategoryCardProps {
  category: CategoryConfig;
}

export function CategoryCard({ category }: CategoryCardProps) {
  const { title, description, icon: Icon, link } = category;
  
  return (
    <Link
      to={link}
      className="group relative overflow-hidden rounded-xl transition-all duration-300"
    >
      <div className="relative h-[300px] p-8 flex flex-col justify-between bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 hover:from-gray-800 hover:to-gray-700 transition-all duration-500">
        {/* Decorative elements */}
        <div className="absolute top-0 right-0 w-32 h-32 bg-yellow-500/10 rounded-full blur-3xl group-hover:bg-yellow-500/20 transition-all duration-500" />
        <div className="absolute bottom-0 left-0 w-32 h-32 bg-gray-600/20 rounded-full blur-3xl" />
        
        {/* Content */}
        <div className="relative">
          <div className="inline-block p-3 bg-gray-800/50 rounded-xl mb-4 group-hover:bg-gray-700/50 transition-all duration-300">
            <Icon className="w-8 h-8 text-yellow-400" />
          </div>
          <h3 className="text-2xl font-bold text-white mb-3">{title}</h3>
          <p className="text-lg text-gray-300">{description}</p>
        </div>

        {/* Call to action */}
        <div className="relative flex items-center gap-2 text-yellow-400 font-medium group-hover:text-yellow-300 transition-colors">
          <span>Find providers</span>
          <svg 
            className="w-5 h-5 transform group-hover:translate-x-1 transition-transform" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
          </svg>
        </div>
      </div>
    </Link>
  );
}