import { GymIcons } from './icons';
import type { CategoryConfig } from '../types';

export const featuredCategories: CategoryConfig[] = [
  {
    title: "Gyms",
    description: "Find fully-equipped gyms with modern facilities and expert trainers",
    icon: GymIcons.default,
    link: "/gym/london",
    image: "https://images.unsplash.com/photo-1534438327276-14e5300c3a48?auto=format&fit=crop&w=1920"
  },
  {
    title: "Boxing Gyms",
    description: "Discover boxing gyms with professional training and equipment",
    icon: GymIcons.boxing,
    link: "/boxing-gym/manchester",
    image: "https://images.unsplash.com/photo-1549719386-74dfcbf7dbed?auto=format&fit=crop&w=1920"
  },
  {
    title: "Gyms with Sauna",
    description: "Find gyms with premium wellness facilities including saunas",
    icon: GymIcons.sauna,
    link: "/gym-with-sauna/birmingham",
    image: "https://images.unsplash.com/photo-1515191107209-c28698631303?auto=format&fit=crop&w=1920"
  },
  {
    title: "Ladies Only Gyms",
    description: "Women-focused fitness centers with dedicated training spaces",
    icon: GymIcons.ladies,
    link: "/ladies-only-gym/london",
    image: "https://images.unsplash.com/photo-1518310383802-640c2de311b2?auto=format&fit=crop&w=1920"
  },
  {
    title: "Gyms with Pool",
    description: "Premium fitness centers featuring swimming pools and aqua facilities",
    icon: GymIcons.pool,
    link: "/gym-with-pool/london",
    image: "https://images.unsplash.com/photo-1576013551627-0cc20b96c2a7?auto=format&fit=crop&w=1920"
  }
];