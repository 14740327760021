import { META_CONTENT } from '../constants/meta';
import type { City, Merchant } from '../types';

export class CanonicalUrlGenerator {
  private static baseUrl = META_CONTENT.BASE_URL.replace(/\/$/, '');

  static getSearchCanonical(merchant: Merchant, city: City, page?: number): string {
    // For pagination pages, always canonicalize to the first page
    if (page && page > 1) {
      return `${this.baseUrl}/${merchant.slug}/${city.slug}`;
    }
    return `${this.baseUrl}/${merchant.slug}/${city.slug}`;
  }

  static getStaticPageCanonical(path: string): string {
    const cleanPath = path.replace(/^\/+|\/+$/g, '');
    return cleanPath ? `${this.baseUrl}/${cleanPath}` : this.baseUrl;
  }

  static getMerchantCategoryCanonical(merchant: Merchant): string {
    return `${this.baseUrl}/${merchant.slug}`;
  }

  static getHomeCanonical(): string {
    return this.baseUrl;
  }
}